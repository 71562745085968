// COLORS

$white:    #fff;
$white1:    #F5F5F5;
$grey: #4A4A4A;
$grey1: #515151;
$grey2: #D7D7D7;
$grey3: #898989;
$grey4: #D1D1D1;
$grey5: #E9E9E9;
$grey6: #D9D9D9;
$black:    #000;
$black1:    #181818;
$black2:    #2C2C2C;
$black3:    #1E1E1E;
$black4:    #18274B1F;

$blue:    #0d6efd;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #198754;
$teal:    #20c997;
$cyan:    #0dcaf0;

$neutral-placeholder: #C1CBD9;

// -- Skazy colors
$skazyblue: #36A9E0;
$skazyyellow: #F1C901;
$skazyorange: #E84E1B;
$skazygreen: #38BB8C;
