html {
  font-size: .625rem; // 1rem = 10px with this line (easier calcul)
}

header {
  h1 {
    @include media-breakpoint-down(md) {
      margin-top: -5rem !important;
    }
  }
}

* {
  cursor: url('../../images/st-custom-cursor.png'), auto;
}

.b-videoletter {
  bottom: calc(-312px / $ecran * $basevw);
  margin-left: calc(575px / $ecran * $basevw);
  @include media-breakpoint-down(xl) {
    bottom: calc(-350px / $ecran * $basevw);
  }

  @include media-breakpoint-down(md) {
    bottom: 42px;
  }

  > div {
    transform: rotate(-16deg);
    @include media-breakpoint-down(xl) {
      transform: rotate(-7deg) scale(2.5);
    }
  }

  video {
    transform: rotate(16deg);
    top: calc(-106.79px / $ecran * $basevw);
    left: calc(23.597px / $ecran * $basevw);
  }

  p {
    @extend .fw-black;
    @extend .font-raleway;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: calc(1500px / $ecran * $basevw);
    background-color: $white;
    mix-blend-mode: screen;
    @include media-breakpoint-down(md) {
      font-size: calc(1700px / $ecran * $basevw);
    }
  }
}

.b-tooltip {
  li {
    svg {
      transition: .1s;
    }
  }

  @for $i from 1 through 4 {
    li:nth-child(n + #{$i}) {
      svg {
        animation-name: animate#{$i};
        animation-duration: $i*3.8+s;
        animation-iteration-count: infinite
      }
    }
  }

  @for $i from 1 through 4 {
    @keyframes animate#{$i} {
      25% {
        transform: rotate(random($i)*(-1)*1+deg) translateY((random(40))+px) translateX(random(40)+px);
      }
      50% {
        transform: rotate(random($i)*(-1)*1+deg) translateY((random(40))+px) translateX(random(40)+px);
      }

      75% {
        transform: rotate(random($i)*(-1)*1+deg) translateY((random(40))+px) translateX(random(40)+px);
      }
    }
  }
}

.uk-sticky-fixed {
  .b-switch-item {
    &.uk-active {
      padding-top: 50px;
    }
  }
}

.b-switch-item {
  img {
    cursor: pointer;
  }
}

.b-chiffres {
  width: 546px;
  height: 435px;
  margin-left: calc(70px / $ecran * $basevw);
  @include media-breakpoint-down(xl) {
    transform: scale(0.8);
    margin-top: -3rem;
  }

  @include media-breakpoint-down(md) {
    transform: scale(1);
    margin-top: 0;
  }

  .square {
    width: 8px;
    height: 8px;
    border-radius: 1px;
    background-color: $white;
    position: absolute;
    @include media-breakpoint-down(md) {
      display: none;
    }

    &:first-child {
      top: -3px;
      left: -3px;
    }

    &:nth-child(2) {
      top: -3px;
      right: -3px;
    }

    &:nth-child(3) {
      bottom: -3px;
      left: -3px;
    }

    &:nth-child(4) {
      bottom: -3px;
      right: -3px;
    }
  }

  .rounded {
    width: 14px;
    height: 14px;
    border: 2px solid $grey3;
    background-color: $white;
    position: absolute;
    border-radius: 100% !important;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .skazygreen {
    @extend .position-relative;
    padding: 36px 44px;
    margin-top: 7px;
    z-index: 1;
    @include media-breakpoint-up(md) {
      margin-left: 65px;
    }

    @include media-breakpoint-down(md) {
      width: 100% !important;
      height: 105px;
      padding: 15px 5px;
    }

    img {
      &.desktop {
        @extend .position-absolute;
        top: 74px;
        right: -230px;
      }

      @include media-breakpoint-down(md) {
        &.responsive {
          &.left {
            margin-left: -5rem;
            margin-top: -5rem;
          }

          &.right {
            right: 0;
            margin-right: -4.5rem;
            margin-bottom: -12rem;
          }
        }
      }
    }

    .square {
      border: 1px solid $skazygreen;
    }

    .rounded {
      right: -7px;
    }
  }

  .skazyorange {
    padding: 30px 43px;
    top: 134px;
    right: 42px;
    z-index: 2;
    @include media-breakpoint-up(md) {
      position: absolute;
    }

    @include media-breakpoint-down(md) {
      width: 100% !important;
      height: 105px;
      padding: 15px 5px;
    }

    img {
      &.desktop {
        @extend .position-absolute;
        max-width: fit-content;
        bottom: -137px;
        right: 103px;
      }

      @include media-breakpoint-down(md) {
        &.responsive {
          &.left {
            left: -4.5rem;
            margin-bottom: -13rem;
          }
        }
      }
    }

    .square {
      border: 1px solid $skazyorange;
    }

    .rounded {
      bottom: -7px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .skazyblue {
    padding: 28px 26px;
    left: 1px;
    bottom: 50px;

    @include media-breakpoint-up(md) {
      position: absolute;
    }

    @include media-breakpoint-down(md) {
      width: 100% !important;
      height: 105px;
      padding: 15px 5px;
    }

    img {
      &.desktop {
        @extend .position-absolute;
        bottom: -40px;
        right: 114px;
      }

      @include media-breakpoint-down(md) {
        &.responsive {
          &.right {
            right: 0;
            margin-right: -4.5rem;
            margin-bottom: -12rem;
          }
        }
      }
    }

    .square {
      border: 1px solid $skazyblue;
    }

    .rounded {
      bottom: -7px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .skazyyellow {
    padding: 18px 37px;
    left: -144px;
    bottom: -139px;

    @include media-breakpoint-up(md) {
      position: absolute;
    }

    @include media-breakpoint-down(md) {
      width: 100% !important;
      height: 105px;
      padding: 15px 5px;
    }

    img {
      @include media-breakpoint-down(md) {
        &.responsive {
          &.left {
            margin-left: -5.5rem;
            margin-top: 6rem;
          }
        }
      }
    }

    .square {
      border: 1px solid $skazyyellow;
    }

    .rounded {
      top: -7px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

.clients {
  padding-top: calc(271px / $ecran * $basevw);
  padding-bottom: calc(271px / $ecran * $basevw);
  // karuia
  .img-1 {
    @extend .position-absolute;
    top: 0;
    left: calc(110px / $ecran * $basevw);
  }

  // eec
  .img-2 {
    @extend .position-absolute;
    top: calc(112px / $ecran * $basevw);
    left: 0;
  }

  // nickel
  .img-3 {
    @extend .position-absolute;
    top: calc(100px / $ecran * $basevw);
    left: calc(232px / $ecran * $basevw);
  }

  // bci
  .img-4 {
    @extend .position-absolute;
    @extend .m-auto;
    top: 0;
    bottom: 0;
    left: 0;
  }

  // opt
  .img-5 {
    @extend .position-absolute;
    left: calc(202px / $ecran * $basevw);
    bottom: calc(172px / $ecran * $basevw);
  }

  // cci
  .img-6 {
    @extend .position-absolute;
    left: calc(35px / $ecran * $basevw);
    bottom: calc(76px / $ecran * $basevw);
  }

  // dittt
  .img-7 {
    @extend .position-absolute;
    left: calc(273px / $ecran * $basevw);
    bottom: 0;
  }

  // fsh
  .img-8 {
    @extend .position-absolute;
    left: calc(470px / $ecran * $basevw);
    bottom: calc(100px / $ecran * $basevw);
  }

  // unc
  .img-9 {
    @extend .position-absolute;
    top: calc(39px / $ecran * $basevw);
    right: calc(459px / $ecran * $basevw);
  }

  // yatoo
  .img-10 {
    @extend .position-absolute;
    top: calc(97px / $ecran * $basevw);
    right: calc(269px / $ecran * $basevw);
  }

  // ass
  .img-11 {
    @extend .position-absolute;
    top: calc(52px / $ecran * $basevw);
    right: calc(42px / $ecran * $basevw);
  }

  // superu
  .img-12 {
    @extend .position-absolute;
    top: calc(180px / $ecran * $basevw);
    right: calc(42px / $ecran * $basevw);
  }

  // fiaf
  .img-13 {
    @extend .position-absolute;
    bottom: calc(203px / $ecran * $basevw);
    right: 0;
  }

  // ps
  .img-14 {
    @extend .position-absolute;
    bottom: calc(156px / $ecran * $basevw);
    right: calc(243px / $ecran * $basevw);
  }

  // cotsuel
  .img-15 {
    @extend .position-absolute;
    bottom: calc(36px / $ecran * $basevw);
    right: calc(357px / $ecran * $basevw);
  }

  // port
  .img-16 {
    @extend .position-absolute;
    bottom: calc(40px / $ecran * $basevw);
    right: calc(19px / $ecran * $basevw);
  }
}

.b-introduction {
  padding: calc(212px / $ecran * $basevw) 0 calc(244px / $ecran * $basevw);

  h2 {
    @include media-breakpoint-up(xl) {
      font-size: calc(60px / $ecran * $basevw);
      line-height: calc(68px / $ecran * $basevw);
    }

    font-size: 28px;
    line-height: 37px;
  }

  #green-abstract {
    @extend .position-absolute;
    @extend .top-0;
    @extend .start-0;
    @include media-breakpoint-down(xl) {
      opacity: 1 !important;
      width: 172.8px;
      height: 175.8px;
      object-fit: contain;
    }

    @include media-breakpoint-down(md) {
      opacity: 1 !important;
    }
  }

  #orange-abstract {
    @extend .position-absolute;
    @extend .top-0;
    @extend .end-0;
    margin-top: 20px;
    @include media-breakpoint-down(xl) {
      opacity: 1 !important;;
      width: 217.8px;
      height: 161.4px;
      object-fit: contain;
    }

    @include media-breakpoint-down(md) {
      opacity: 1 !important;
    }
  }

  #blue-abstract {
    @extend .position-absolute;
    @extend .bottom-0;
    @extend .start-0;
    margin-left: 30px;
    @include media-breakpoint-down(xl) {
      display: block !important;
      opacity: 1 !important;
      width: 168.48px;
      height: 108.54px;
      object-fit: contain;
    }
  }

  #yellow-abstract {
    @extend .position-absolute;
    @extend .bottom-0;
    @extend .end-0;
    @include media-breakpoint-down(xl) {
      display: block !important;
      opacity: 1 !important;
      width: 221.4px;
      height: 126.9px;
      object-fit: contain;
    }
  }

  #arrow-curve {
    @extend .position-absolute;
    transform: scale(1.5);
    left: calc(700px / $ecran * $basevw);
    bottom: calc(100px / $ecran * $basevw);
    @include media-breakpoint-down(xxl) {
      bottom: calc(30px / $ecran * $basevw);
    }

    @include media-breakpoint-down(xl) {
      transform: scale(0.8);
      left: calc(500px / $ecran * $basevw);
      bottom: calc(15px / $ecran * $basevw);
    }

    @include media-breakpoint-down(md) {
      transform: scale(1);
      left: 0;
      bottom: 13rem;
    }
  }
}

#substract {
  &-yellow {
    @include media-breakpoint-down(xl) {
      margin-top: -10rem;
      margin-left: -30rem;
    }

    @include media-breakpoint-down(md) {
      margin-top: 0;
      margin-left: -24rem;
    }
  }

  &-green {
    @include media-breakpoint-down(xl) {
      margin-top: -10rem;
      margin-right: -20rem;
    }

    @include media-breakpoint-down(md) {
      margin-top: 0;
      margin-right: -29rem;
    }
  }

  &-blue {
    @include media-breakpoint-down(xl) {
      margin-bottom: 2rem;
      margin-left: -24rem;
    }
  }

  &-orange {
    @include media-breakpoint-down(xl) {
      margin-bottom: 15rem;
      margin-right: -15rem;
    }
  }
}

#tooltip {
  &-formation {
    width: max-content;
    top: 0;
    margin-left: calc(-500px / $ecran * $basevw);
    @include media-breakpoint-down(xl) {
      margin-left: 0;
      right: 14rem;
      top: -32rem;
    }

    @include media-breakpoint-down(lg) {
      margin-left: 0;
      right: 4rem;
      top: -29rem;
    }

    @include media-breakpoint-down(md) {
      right: 0rem;
      top: -20rem;
    }
  }

  &-data {
    right: 0;
    margin-top: calc(-70px / $ecran * $basevw);
    margin-right: calc(-560px / $ecran * $basevw);
    @include media-breakpoint-down(xxl) {
      margin-right: calc(-360px / $ecran * $basevw);
    }

    @include media-breakpoint-down(xl) {
      top: -16rem;
      margin-right: 0;
      left: -9rem;
    }

    @include media-breakpoint-down(lg) {
      top: -10rem;
      left: -26rem;
    }

    @include media-breakpoint-down(md) {
      top: -13rem;
      left: -7rem;
    }
  }

  &-com {
    @include media-breakpoint-up(xl) {
      margin-left: calc(-250px / $ecran * $basevw);
      margin-bottom: calc(-111px / $ecran * $basevw);
    }

    @include media-breakpoint-down(xl) {
      right: -7rem;
      bottom: 14rem;
    }

    @include media-breakpoint-down(lg) {
      right: -25rem;
      bottom: 14rem;
    }

    @include media-breakpoint-down(md) {
      left: -8rem;
      bottom: -20rem;
    }
  }

  &-numerique {
    @include media-breakpoint-up(xl) {
      top: 0;
      right: 0;
      margin-right: calc(-360px / $ecran * $basevw);
      margin-top: calc(-130px / $ecran * $basevw);
    }

    @include media-breakpoint-down(xl) {
      left: 0;
      bottom: -28rem;
    }

    @include media-breakpoint-down(lg) {
      left: -14rem;
      bottom: -24rem;
    }

    @include media-breakpoint-down(md) {
      right: -6rem;
      bottom: -22rem;
      left: initial;
    }
  }
}

.menu-footer-right {
  @include media-breakpoint-between(md, lg) {
    margin-top: -35px;
  }

  ul {
    &:first-child {
      @include media-breakpoint-down(xl) {
        flex-wrap: wrap;
        justify-content: end;
      }

      @include media-breakpoint-between(md, lg) {
        align-items: center;
        align-content: center;
        gap: 20px 40px;
        align-self: stretch;
        flex-wrap: wrap;
      }
    }
  }
}

.menu-responsive {
  @extend .border-0;

  #offcanvas-bar-head {
    @extend .p-16;
    box-shadow: 0px 8px 16px -6px $black4;
    svg {
      transform: scale(0.7);
      transform-origin: 0 center;
    }
  }

  .accordion-button {
    &:after {
      @extend .d-none;
    }

    &.collapsed {
      #close {
        @extend .d-none;
      }
    }

    &:not(.collapsed) {
      #burger {
        @extend .d-none;
      }
    }
  }

  footer {
    @extend .h-100;
  }
}

#collapseOne {
  padding-top: 16px;
  z-index: 5;
  top: 97px;
}

.uk-switcher {
  @include media-breakpoint-up(xl) {
    margin-top: 38px !important;
    top: 0 !important;
  }
}

.item-card {
  @include media-breakpoint-down(md) {
    opacity: 1 !important;
  }
}

#substract-green,
#substract-yellow {
  @include media-breakpoint-down(md) {
    opacity: 1 !important;
  }
}