.uk-tab > .uk-active > a {
  color: transparent;
  border-color: transparent;
}

.uk-tab::before {
  @extend .d-none;
}

* + address, * + dl, * + fieldset, * + figure, * + ol, * + p, * + pre, * + ul {
  margin-top: 0;
}

.uk-dotnav > * > * {
  width: 16px;
  height: 16px;
  border-color: $grey6;
}

.uk-dotnav > .uk-active > * {
  @extend .bg-dark;
  width: 16px;
  height: 16px;
  box-shadow: inset 0px 0px 0px 2px $white;
}

.uk-sticky-placeholder {
  @extend .d-none;
}

.uk-offcanvas-bar {
  width: 100%;
  @extend .bg-white;
  @extend .p-0;
}

ul.uk-nav-sub {
  padding: 5px 15px 5px 15px;
}