.card {
  @extend .border-0;
  min-height: 558.4px;
  @include media-breakpoint-down(xxl) {
    min-height: 250px;
  }
  .abstract {
    z-index: 0;
    mask-image: linear-gradient(to top, transparent 10%, black 75%);
  }

  .img {
    max-width: initial;
    margin-left: -5rem;
    z-index: 1;
    transform: scale(1.1);
    opacity: 0;
    transition: all 0.5s ease-out;
  }

  .abstract {
    transform: translateX(0);
    left: 0;
    transition: all 0.5s ease-out;
  }

  .description {
    margin-bottom: -7rem;
    transition: all 0.5s ease-out;
    &-description {
      opacity: 0;
      transition: all 0.5s ease-out;
    }
  }

  &-numerique {
    .img {
      left: -18.5rem;
      top: -5rem;
      mask-image: linear-gradient(to top, transparent 25%, black 75%);
      @include media-breakpoint-down(xxl) {
        left: 17rem;
        width: 592px;
        height: 283px;
        object-fit: contain;
      }

      @include media-breakpoint-down(xxl) {
        left: -5rem;
      }
    }
  }

  &-data {
    .img {
      left: -7%;
      top: -33%;
      mask-image: linear-gradient(to top, transparent 10%, black 75%);
      @include media-breakpoint-down(xxl) {
        left: 28rem;
        top: -18%;
        width: 350px;
        object-fit: contain;
      }

      @include media-breakpoint-down(md) {
        left: 10rem;
      }
    }
  }

  &-com {
    .img {
      width: 130%;
      height: 390px;
      left: -15%;
      top: -1.5%;
      mask-image: linear-gradient(to top, transparent 15%, black 75%);
      @include media-breakpoint-down(xxl) {
        left: 37rem;
        top: -15%;
        width: 243px;
        height: 243px;
        object-fit: contain;
      }

      @include media-breakpoint-down(md) {
        left: 15rem;
        top: -10%;
      }
    }
  }

  &-formation {
    .img {
      left: -30%;
      top: -6%;
      mask-image: linear-gradient(to top, transparent 20%, black 75%);
      @include media-breakpoint-down(xxl) {
        left: 33rem;
        top: -12%;
        width: 350px;
        object-fit: contain;
      }

      @include media-breakpoint-down(md) {
        left: 11rem;
      }
    }
  }

  &-slide {
    @extend .w-md-50;
    .description {
      @extend .d-block;
    }

    .arrow-right {
      @extend .d-none;
    }

    ul {
      @extend .h-100
    }

    //&-title {
    //  pointer-events: none;
    //}
  }
}

.card-hover {
  &:hover {
    .img {
      margin-left: 0;
      transform: scale(1);
      opacity: 1;
    }

    .abstract {
      left: 100%;
      transform: translateX(-100%);
    }

    .description {
      margin-bottom: 0;
      &-description {
        opacity: 1;
      }
    }
  }
}